<template>
	<div>
		<a-button type="link" @click="showAct"  icon="setting">设置</a-button>
		
		<a-modal
			  width="900px"
		      title="设置组件"
		      :visible="showEdit"
			  :footer="null"
		      @cancel="handleCancel"
		    >
			<div class="ft14 cl-main">基本设置</div>
			<div class="mt12">
				   距离上组件 <a-input-number id="inputNumber" v-model="mydatas.style.mt" :min="0" :max="50" />px
			</div>
			<div class="mt12">
				   样式 <a-radio-group v-model="mydatas.style.type" button-style="solid">
						<a-radio-button value="0">
						  默认样式
						</a-radio-button>
						<a-radio-button value="1">
						  小块状
						</a-radio-button>
						<a-radio-button value="2">
						  大块状
						</a-radio-button>
						<a-radio-button value="3">
						  每行4个
						</a-radio-button>
					  </a-radio-group> 
			</div>
			<div v-if="mydatas.style.type > 0" class="mt12">
				   块状间距 <a-input-number id="inputNumber2" v-model="mydatas.style.space" :min="0" :max="50" />px
			</div>
			<div v-if="mydatas.style.type == 0" class="mt12">
				   是否显示背景 <a-radio-group v-model="mydatas.style.isBg" button-style="solid">
						<a-radio-button value="0">
						  不显示
						</a-radio-button>
						<a-radio-button value="1">
						  显示
						</a-radio-button>
					  </a-radio-group> 
			</div>
			<div class="ft14 cl-main mt12">图标导航</div>
			<table cellpadding="0" cellspacing="0" class="icon-edit-table mt12">
				<thead>
					<tr>
						<th>图片</th>
						<th>名称</th>
						<th>链接地址</th>
						<th style="text-align: right;">操作</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item,index) in mydatas.datas" :key="index">
						<td>
							<upload-img typeClass="small" v-model="mydatas.datas[index].pic"></upload-img>
						</td>
						<td>
							<a-input v-model="mydatas.datas[index].name"></a-input>
						</td>
						<td>
							<select-link v-model="mydatas.datas[index].url"></select-link>
						</td>
						<td style="flex">
							
							<a-button type="link" @click="upAct(index)" v-if="index > 0" icon="arrow-up"></a-button>
							<a-button type="link" @click="downAct(index)" v-if="(index < mydatas.datas.length - 1 ) && mydatas.datas.length >1" icon="arrow-down"></a-button>
							
							
							<a-button type="link" @click="deleteAct(index)" icon="delete" style="color:#F76260"></a-button>
							
						</td>
					</tr>
				</tbody>
			</table>
			
			<div v-if="mydatas.datas.length < 10" class="flex center mt16">
				<a-button @click="addicon" type="link" icon="plus">新增一条</a-button>
			</div>
			
		</a-modal>
	</div>
</template>

<script>
	import uploadImg from '../../../../../components/upload/upload.vue';
	import selectLink from '../selectLink.vue';
	export default{
		components:{
			uploadImg,
			selectLink
		},
		props:{
			value:{
				type:Object,
				default:function(){
					return new Object;
				}
			},
		},
		watch:{
			mydatas:{
				handler(newName, oldName){
					this.$emit('input',this.mydatas);
				},
				immediate: false,
				deep: true
			},
			value:{
				handler(newName, oldName){
					if(this.value != this.mydatas){
						this.mydatas = this.value;
					}
				},
				immediate: false,
				deep: true
			}
		},
		data(){
			return {
				mydatas:null,
				showEdit:false,
			}
		},
		created(){
			this.mydatas = this.value;
		},
		methods:{
			showAct(){
				this.showEdit = true;
			},
			handleCancel(){
				this.showEdit = false;
			},
			addicon(){
				this.mydatas.datas.push({
					pic:'',
					name:'',
					url:''
				});
			},
			deleteAct(index){
				this.mydatas.datas.splice(index,1);
			},
			downAct(index){
				let mydatas = JSON.parse(JSON.stringify(this.mydatas));
				let down = parseInt(index) + 1;
				let my = mydatas.datas[index];
				let d  = mydatas.datas[down];
				mydatas.datas[index] =  d;
				mydatas.datas[down]  = my;
				this.mydatas = mydatas;
			},
			upAct(index){
				let mydatas = JSON.parse(JSON.stringify(this.mydatas));
				let up = parseInt(index) - 1;
				let my = mydatas.datas[index];
				let u = mydatas.datas[up];
				mydatas.datas[index] =  u;
				mydatas.datas[up]  = my;
				this.mydatas = mydatas;
			},
		}
	}
</script>

<style>
	.icon-edit-table{
		background: #F5F6F8;
		width: 100%;
	}
	.icon-edit-table thead th{
		padding: 10px 20px;
		font-size: 14px;
		color:#666666;
	}
	.icon-edit-table tbody td{
		padding: 10px 20px;
	}
	.icon-edit-table tbody tr{
		border-top: 1px solid #E4E6ED;
	}
</style>