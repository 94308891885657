export const footers = [
	{name:'分类',model:'category',icon:'icontabbar05'},
	{name:'相册',model:'look',icon:'icontabbar06'},
	{name:'购物车',model:'cart',icon:'icontabbar07'},
	{name:'预约',model:'appointment',icon:'icontabbar12'},
	{name:'买单',model:'buy',icon:'icontabbar02'},
	{name:'抢购',model:'tuan',icon:'icontabbar14'},
	{name:'次卡',model:'oncecard',icon:'icontabbar13'},
	{name:'优惠券',model:'coupon',icon:'icontabbar15'},
];

//打开小程序 就是 miniapp:wx*** 电话就是tel:****
export const plugins = [
	
	{
		name:'轮播图片',
		key:'banner',
		style:{
			mt:0,//距离顶部
			h:150,//高度
			r:8,//圆角
		},
		datas:[
			{pic:'', url:''}
		],
	},
	{
		name:'图片',
		key:'pics',
		style:{
			mt:16,//距离顶部
			h:150,//高度 个体最大高度
			space:0,//间距 当图片大于1的时候  最多4张图片
		},
		datas:[
			{pic:'', url:''}
		],
	},
	{
		name:'视频',
		key:'video',
		style:{
			mt:16,//距离顶部
			h:150,//高度 个体最大高度
			url:'',
		},
		datas:[
			
		],
	},
	{
		name:'图标导航',
		key:'icon',
		style:{
			type:0,//样式1 样式2  样式3
			mt:16,//距离顶部
			isBg:1,
			space:10,
		},
		datas:[
			{pic:'', name:'图标',url:''},
			{pic:'', name:'图标',url:''},
			{pic:'', name:'图标',url:''},
			{pic:'', name:'图标',url:''},
			{pic:'', name:'图标',url:''},
		],
	},
	
	
	
	{
		name:'优惠券',
		key:'coupon',
		style:{
			mt:16,//距离顶部
			icon:'',
			title:'优惠券',
		},
		datas:[
			{name:'10元优惠券',money_limit:100,valid_bg_time:'2021.05.16',valid_end_time:'2030.05.16',coupon_money:10},
		],
	},
	{
		name:'优惠抢购',
		key:'tuan',
		style:{
			mt:16,//距离顶部
			num:1,
			icon:'',
			title:'优惠抢购',
		},
		datas:[
			{cover_img:'',abbreviation:'',flash_price:100,vip_price:100,market_price:120,flash_sale_djs:86400,is_flash_sale_expire:0,is_flash_sale:1,fictitious_take_count:10}
		], 
	},
	{
		name:'办理次卡',
		key:'oncecard',
		style:{
			mt:16,//距离顶部
			num:1,
			icon:'',
			title:'办理次卡',
		},
		datas:[
			{cover_img:'',name:'',flash_price:100,price:100,save_money:1000,flash_sale_djs:86400,is_flash_sale_expire:0,is_flash_sale:1,fictitious_take_count:10}	
		],
	},
	{
		name:'店铺地址',
		key:'mendian',
		style:{
			mt:16,//距离顶部
		},
		datas:[
			{logo:'',name:'',address:'',business_hours:''}
		],
	},
	{
		name:'门店相册',
		key:'look',
		style:{
			mt:16,//距离顶部
			icon:'',
			title:'门店相册',
		},
		datas:[
			{photo:'',length:5},
			{photo:'',length:5},
			{photo:'',length:5},
		],
	},
];