<template>
	<div class="coupon-box pd16_15">
		<div class="flex alcenter space">
			<div class="flex alcenter">
				<img style="width: 20px; height: 20px;" class="mr10" v-if="datas.style.icon && datas.style.icon !='' " :src="datas.style.icon" />
				<img v-else style="width: 20px; height: 20px;" class="mr10" src="../../../../../assets/image/mall_home_coupon@3x.png" />
				<span class="ft16 ftw600 cl-main">{{datas.style.title ? datas.style.title:'优惠券'}}</span>
			</div>
			<div>
				<span class="ft12 cl-main">查看全部</span>
				<span class="iconfont iconarrowright_black ft12 cl-main ml5"></span>
			</div>
		</div>
		<div class="mt16">
			<a-spin :spinning="loading">
				<div v-if="calldata == null" class="plugin-empty" style="height: 120px;line-height: 120px;">暂无数据</div> 
				<div v-else class="coupon-item cl-w">
					<img class="bg" src="../../../../../assets/image/base_img_couponbg01@2x.png" />
					<div class="coupon-item-main flex">
						<div class="coupon-l pd16_15">
							<div class="text-center ft16 ftw500">{{calldata.name}}</div>
						
							<div class="flex center mt8">
								<div class="coupon-need-money" style="color:#FF583F;">
									满{{calldata.money_limit}}元可用
								</div>
							</div>
							<div class="text-center mt8 ft12">有效期：{{calldata.valid_bg_time}}-{{calldata.valid_end_time}}</div>
						</div>
						<div class="coupon-r pt20">
							<div class="text-center ft20 ftw600">¥{{calldata.coupon_money}}</div>
							<div class="mt12 flex center">
								<div  class="coupon-btn coupon-get">领取</div>
							</div>
						</div>
					</div>
				</div>	
			</a-spin>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			mainColor:{
				type:String,
				default:'',
			},
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		data(){
			return{
				loading:false,
				hasIds:[],
			}
		},
		computed:{
			calldata(){
				return this.datas.datas[0];
			}
		},
		created(){
			
		},
		methods:{
			
		}
	}
</script>

<style>
	.coupon-box{
		background: #FFFFFF;
		border-radius: 8px;
		line-height: 1;
		position: relative;
		overflow: hidden;
	}
	
	.coupon-item{
		width: 100%;
		height:106px;
		position: relative;
	}
	.coupon-item .bg{
		width: 100%;
		height: 106px;
	}
	.coupon-item-main{
		width: 100%;
		height: 106px;
		position: absolute;
		left: 0;
		top: 0;
	}
	
	.coupon-item-main .coupon-l{
		width: 66%;
	}
	.coupon-item-main .coupon-r{
		width: 34%;
	}
	
	.coupon-need-money{
		width: 100px;
		height: 20px;
		background: #FFFFFF;
		border-radius: 4px;
		text-align: center;
		line-height: 20px;
		font-size: 12px;
	}
	
	.coupon-btn{
		width: 64px;
		height: 24px;
		border-radius:12px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.coupon-get{
		background: linear-gradient(180deg, #FFFFFF 0%, #FFCDB2 100%);
		box-shadow: 0px 4px 8px 0px #FF3B1C;
		font-size: 14px;
		font-weight: 600;
		color:#FF583F;
	}
</style>