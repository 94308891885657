<template>
	<div>
		<a-button type="link" @click="showAct"  icon="setting">设置</a-button>
		
		<a-modal
			  width="900px"
		      title="设置组件"
		      :visible="showEdit"
			  :footer="null"
		      @cancel="handleCancel"
		    >
			<div class="ft14 cl-main">基本设置</div>
			<div class="mt12">
				   距离上组件 <a-input-number id="inputNumber" v-model="mydatas.style.mt" :min="0" :max="50" />px
			</div>
			<div class="mt12">
				   高度 <a-input-number id="inputNumber" v-model="mydatas.style.h" :min="0" :max="500" />px
			</div>
			<div class="mt12">
				   圆角 <a-input-number id="inputNumber" v-model="mydatas.style.r" :min="0" :max="32" />px
			</div>
			<div class="ft14 cl-main mt12">BANNER上传</div>
			<table cellpadding="0" cellspacing="0" class="banner-edit-table mt12">
				<thead>
					<tr>
						<th>图片</th>
						<th>链接地址</th>
						<th style="text-align: right;">操作</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item,index) in mydatas.datas" :key="index">
						<td>
							<upload-img typeClass="small" v-model="mydatas.datas[index].pic"></upload-img>
						</td>
						<td>
							<select-link v-model="mydatas.datas[index].url"></select-link>
						</td>
						<td  class="flex">
							<a-button type="link" @click="upAct(index)" v-if="index > 0" icon="arrow-up"></a-button>
							<a-button type="link" @click="downAct(index)" v-if="(index < mydatas.datas.length - 1 ) && mydatas.datas.length >1" icon="arrow-down"></a-button>
							
							<a-button type="link" @click="deleteAct(index)" icon="delete" style="color:#F76260"></a-button>
						</td>
					</tr>
				</tbody>
			</table>
			
			<div v-if="mydatas.datas.length < 5" class="flex center mt16">
				<a-button @click="addBanner" type="link" icon="plus">新增一条</a-button>
			</div>
			
		</a-modal>
	</div>
</template>

<script>
	import uploadImg from '../../../../../components/upload/upload.vue';
	import selectLink from '../selectLink.vue';
	export default{
		components:{
			uploadImg,
			selectLink
		},
		props:{
			value:{
				type:Object,
				default:function(){
					return new Object;
				}
			},
		},
		watch:{
			mydatas:{
				handler(newName, oldName){
					this.$emit('input',this.mydatas);
				},
				immediate: false,
				deep: true
			},
			value:{
				handler(newName, oldName){
					if(this.value != this.mydatas){
						this.mydatas = this.value;
					}
				},
				immediate: false,
				deep: true
			}
		},
		data(){
			return {
				mydatas:null,
				showEdit:false,
			}
		},
		created(){
			this.mydatas = this.value;
		},
		methods:{
			showAct(){
				this.showEdit = true;
			},
			handleCancel(){
				this.showEdit = false;
			},
			addBanner(){
				this.mydatas.datas.push({
					pic:'',
					url:''
				});
			},
			deleteAct(index){
				this.mydatas.datas.splice(index,1);
			},
			downAct(index){
				let mydatas = JSON.parse(JSON.stringify(this.mydatas));
				let down = parseInt(index) + 1;
				let my = mydatas.datas[index];
				let d  = mydatas.datas[down];
				mydatas.datas[index] =  d;
				mydatas.datas[down]  = my;
				this.mydatas = mydatas;
			},
			upAct(index){
				let mydatas = JSON.parse(JSON.stringify(this.mydatas));
				let up = parseInt(index) - 1;
				let my = mydatas.datas[index];
				let u = mydatas.datas[up];
				mydatas.datas[index] =  u;
				mydatas.datas[up]  = my;
				this.mydatas = mydatas;
			},
		}
	}
</script>

<style>
	.banner-edit-table{
		background: #F5F6F8;
		width: 100%;
	}
	.banner-edit-table thead th{
		padding: 10px 20px;
		font-size: 14px;
		color:#666666;
	}
	.banner-edit-table tbody td{
		padding: 10px 20px;
	}
	.banner-edit-table tbody tr{
		border-top: 1px solid #E4E6ED;
	}
</style>