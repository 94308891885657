<template>
	<div>
		<a-button type="link" @click="showAct"  icon="setting">设置</a-button>
		
		<a-modal
			  width="480px"
		      title="设置组件"
		      :visible="showEdit"
			  :footer="null"
		      @cancel="handleCancel"
		    >
			<div class="mt12 flex alcenter">
			   <div style="width: 100px;">距离上组件</div> <a-input-number v-model="mydatas.style.mt" :min="0" :max="50" />px
			</div>
			
			<div class="mt12 flex alcenter">
				<div style="width: 100px;">模块标题</div>
				<a-input v-model="mydatas.style.title" style="width: 200px;"></a-input>
			</div>
			<div class="mt12 flex alcenter">
				<div style="width: 100px;">模块图标</div>
				<div>
					<upload-img v-model="mydatas.style.icon" typeClass="small"></upload-img>
				</div>
			</div>
			 <div class="mt12">
			 	<table style="width: 100%;" class="wxb-table">
			 		<thead>
			 			<tr>
			 				<th>缩略图</th>
			 				<th>数量</th>
			 				
			 			</tr>
			 		</thead>
			 		<tbody>
			 			<tr v-for="(item,index) in  mydatas.datas" :key="index">
			 				<td>
			 					<upload-img v-model="mydatas.datas[index].photo"  typeClass="small"></upload-img>
			 				</td>
			 				
			 				<td>
			 					<a-input-number v-model="mydatas.datas[index].length" :min="0" :max="100"></a-input-number>
			 				</td>
			 			</tr>
			 		</tbody>
			 	</table>
			 </div>
		</a-modal>
	</div>
</template>

<script>
	import uploadImg from '../../../../../components/upload/upload.vue';
	export default{
		props:{
			value:{
				type:Object,
				default:function(){
					return new Object;
				}
			},
		},
		components: {
			uploadImg
		},
		watch:{
			mydatas:{
				handler(newName, oldName){
					this.$emit('input',this.mydatas);
				},
				immediate: false,
				deep: true
			},
			value:{
				handler(newName, oldName){
					if(this.value != this.mydatas){
						this.mydatas = this.value;
					}
				},
				immediate: false,
				deep: true
			}
		},
		data(){
			return {
				mydatas:null,
				showEdit:false,
			}
		},
		created(){
			this.mydatas = this.value;
		},
		methods:{
			showAct(){
				this.showEdit = true;
			},
			handleCancel(){
				this.showEdit = false;
			}
		}
	}
</script>

<style>
	
</style>