<template>
	<div>
		<table cellpadding="0" cellspacing="0" class="footer-table">
			
			<tbody>
				<tr v-for="(item,index) in myfooter" :key="index">
					<td>
						<i class="iconfont ft22 mr15 cl-green"  :class="showFooter[index].icon"></i>
						{{showFooter[index].name}}
					</td>
					
					<td class="flex alcenter end">
						
						<a-button type="link" @click="upAct(index)" v-if="index > 0" icon="arrow-up"></a-button>
						<a-button type="link" @click="downAct(index)" v-if="(index < myfooter.length - 1 ) && myfooter.length >1" icon="arrow-down"></a-button>
						
						<a-button type="link" @click="deleteAct(index)" icon="delete" style="color:#F76260"></a-button>
					</td>
				</tr>
			</tbody>
		</table>
	
		<div v-if="myfooter.length < 3" class="flex center mt16">
			<a-button @click="addFooter" type="link" icon="plus">新增一条</a-button>
		</div>
		
		<a-modal
			  width="480px"
		      title="添加导航菜单"
		      :visible="show"
			  :footer="null"
		      @cancel="handleCancel"
		    >
			<div class="flex wrap space">
				<div v-for="(item,index) in  footers" :key="index"  :class="{mt16:index > 1}" class="footer-select-item">
					<div class="flex alcenter">
						<i class="iconfont ft20 cl-green" :class="item.icon"></i>
						<div class="ft14 ftw600 cl-main ml10">{{item.name}}</div>
					</div>
					<div class="select-icon" @click="addFooterAct(item.model)" :class="{on:myfooter.indexOf(item.model) == -1}">
						<i v-if="myfooter.indexOf(item.model) > -1" class="iconfont iconduihao ft12"></i>
						<i v-else class="iconfont iconadd_big  ft12"></i>
					</div>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import {footers} from './template.js';
	export default{
		props:{
			value:{
				type:Array,
				default:''
			}
		},
		watch:{
			myfooter:{
				handler(newName, oldName){
					this.$emit('input',this.myfooter);
				},
				immediate: false,
				deep: true
			},
			value:{
				handler(newName, oldName){
					if(this.value != this.myfooter){
						this.myfooter = this.value;
					}
				},
				immediate: false,
				deep: true
			}
		},
		computed:{
			showFooter(){
				let arr = new Array;
				for(var a  in this.myfooter){
					let datas = this.footers.filter((item)=>{
						return item.model == this.myfooter[a];
					})[0];
					arr.push(datas);
				}
				return arr;
			}
		},
		data(){
			return {
				myfooter:[],
				footers:[],
				show:false,
			}
		},
		created(){
			this.myfooter = this.value;
			this.footers = footers;
		},
		methods:{
			handleCancel(){
				this.show = false;
			},
			addFooter(){
				this.show = true;
			},
			addFooterAct(m){
				if(this.myfooter.indexOf(m) == -1){
					this.myfooter.push(m);
					this.show = false;
				}
			},
			deleteAct(index){
				this.myfooter.splice(index,1);
			},
			downAct(index){
				let myfooter = JSON.parse(JSON.stringify(this.myfooter));
				let down = parseInt(index) + 1;
				let my = myfooter[index];
				let d  = myfooter[down];
				myfooter[index] =  d;
				myfooter[down]  = my;
				this.myfooter = myfooter;
			},
			upAct(index){
				let myfooter = JSON.parse(JSON.stringify(this.myfooter));
				let up = parseInt(index) - 1;
				let my = myfooter[index];
				let u = myfooter[up];
				myfooter[index] =  u;
				myfooter[up]  = my;
				this.myfooter = myfooter;
			},
		}
	}
</script>

<style>
	
	.footer-select-item{
		width: 200px;
		height: 50px;
		background: #FFFFFF;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border-radius: 4px;
		padding: 0 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
	}
	.footer-select-item .select-icon{
		width: 48px;
		height: 32px;
		background: #F5F6FA;
		border-radius: 16px;
		text-align: center;
		line-height: 32px;
		color:#AEB2C1;
	}
	.footer-select-item .select-icon.on{
		color:#FFFFFF;
		background: #5E40FF;
	}
	
	.footer-table{
		background: #FFFFFF;
		width: 100%;
	}
	
	.footer-table tbody td{
		padding: 10px 20px;
	}
	.footer-table tbody tr{
		border-bottom: 1px solid #E4E6ED;
	}
	.footer-table tbody tr:last-child{
		border:none;
	}
</style>